import { translations } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Amplify, I18n } from 'aws-amplify';
import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'typesafe-actions';
import AuthGuard from './components/AuthGuard';
import Error from './components/Error';
import FirewallCheck from './components/FirewallCheckPopup';
import MaintenanceBanner from './components/MaintenanceBanner';
import Notifications from './components/Notifications';
import * as commonActions from './features/common/actions';
import * as commonSelectors from './features/common/selectors';
import AuthenticatedRoutes from './routes/AuthenticatedRoutes';
import UnauthenticatedRoutes from './routes/UnauthenticatedRoutes';
import { history } from './store';
import { Header } from './stories/Header';
import AmplifyConfig, {
  FrontendEnvironment,
  getEnvironment,
} from './util/amplifyConfig';
import { Errors } from './features/errors/components/Errors';

Amplify.configure(AmplifyConfig);
I18n.putVocabularies(translations);
I18n.setLanguage('de');

const mapStateToProps = (state: RootState) => ({
  user: commonSelectors.user(state.common),
  userError: commonSelectors.userError(state.common),
});

const dispatchProps = {
  signOut: commonActions.signOutAsync.request,
  signOutLocal: commonActions.signOutLocalAsync.request,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const App: React.FC<Props> = ({ user, userError, signOut, signOutLocal }) => {
  const onSignOut =
    getEnvironment() === FrontendEnvironment.LOCAL ? signOutLocal : signOut;
  return (
    <ConnectedRouter history={history}>
      <FirewallCheck />
      {getEnvironment() !== FrontendEnvironment.LOCAL && <AuthGuard />}
      <Errors />
      {userError ? (
        <>
          <Header
            userRole={user?.role}
            onSignOut={onSignOut}
            Notifications={<Notifications />}
          />
          <Error error={userError.message} />
        </>
      ) : user ? (
        <>
          <MaintenanceBanner />
          <Header
            userRole={user.role}
            onSignOut={onSignOut}
            Notifications={<Notifications />}
          />
          <AuthenticatedRoutes />
        </>
      ) : (
        <UnauthenticatedRoutes />
      )}
    </ConnectedRouter>
  );
};

export default connect(mapStateToProps, dispatchProps)(App);
