import { WsSecProtocolName } from '../../models/zod/WsSchema';
import { createReducer } from 'typesafe-actions';
import { connectWebsocket, setWebsocketCustomer } from './actions';
import { webSocketConfig } from '../../util/amplifyConfig';
import {
  AsyncResource,
  asyncResourceRequest,
  asyncResourceSuccess,
} from '../../store/async-resource';

interface State {
  selectedCustomer: AsyncResource<string | undefined>;
  websocket: WebSocket | undefined;
}

const initialState: State = {
  selectedCustomer: asyncResourceSuccess(undefined),
  websocket: undefined,
} as State;

export const websocketReducer = createReducer(initialState)
  .handleAction(connectWebsocket, (state, { payload: { token } }) => {
    const websocket = new WebSocket(webSocketConfig.endpoint, [
      webSocketConfig.credentials(token)!,
      WsSecProtocolName,
    ]);

    return {
      ...state,
      websocket,
    };
  })
  .handleAction(setWebsocketCustomer.request, (state, { payload }) => {
    return {
      ...state,
      selectedCustomer: asyncResourceRequest(),
    };
  })
  .handleAction(setWebsocketCustomer.success, (state, { payload }) => {
    return {
      ...state,
      selectedCustomer: asyncResourceSuccess(payload),
    };
  });

export default websocketReducer;
