// This file is generated by UpdateOpenapiCache.ts
// Do not edit this file directly. Instead, edit the file
// WsSchema.ts in the gaas-openapi project
import { z } from 'zod';

type InferredSchemaType<T> = T extends z.ZodType<infer U, any>
  ? Required<U> extends { message: any }
    ? Required<U>['message']
    : never
  : never;

///////////////////////////////////////
///     INCOMING MESSAGES           ///
///////////////////////////////////////

export const WsSetCustomerSchema = z
  .object({
    action: z.literal('set-customer'),
    messageId: z.string().uuid(),
    message: z
      .object({
        customerId: z.string(),
      })
      .strict(),
  })
  .strict();
export type WsSetCustomerSchemaMessageType = z.infer<
  typeof WsSetCustomerSchema
>;

export type WsSetCustomerSchemaType = InferredSchemaType<
  typeof WsSetCustomerSchema
>;

export type WsSetCustomerMessageType = z.infer<typeof WsSetCustomerSchema>;

export const WsFetchValveUpdatesSchema = z
  .object({
    action: z.literal('fetch-valve-updates'),
    messageId: z.string().uuid(),
    message: z.object({
      customerId: z.string(),
    }),
  })
  .strict();
export type WsFetchValveUpdatesMessageSchema = z.infer<
  typeof WsFetchValveUpdatesSchema
>;
export type WsFetchValveUpdatesSchemaType = InferredSchemaType<
  typeof WsFetchValveUpdatesSchema
>;

export const WsInboundSchema = z.discriminatedUnion('action', [
  WsSetCustomerSchema,
  WsFetchValveUpdatesSchema,
]);
export type WsInboundSchemaType = z.infer<typeof WsInboundSchema>;
const bla = z.union(WsInboundSchema.options);
export type WsInboundSchemaOptionsType = z.infer<typeof bla>;

///////////////////////////////////////
///     OUTGOING MESSAGES           ///
///////////////////////////////////////

export const WsStatusSchema = z
  .object({
    messageType: z.literal('status'),
    messageId: z.string().uuid(),
    status: z.enum(['ok', 'error']),
    message: z.string().optional(),
  })
  .strict();
export type WsStatusSchemaType = z.infer<typeof WsStatusSchema>;

export const makeOkStatus = (
  messageId: string,
  message?: string
): WsStatusSchemaType => ({
  messageId,
  messageType: 'status',
  status: 'ok',
  message,
});
export const makeErrorStatus = (
  messageId: string,
  message: string
): WsStatusSchemaType => ({
  messageId,
  messageType: 'status',
  status: 'error',
  message,
});

///////////////////////////////////////
///     STREAMING MESSAGES          ///
///////////////////////////////////////

const WsStreamingContainerValvePairingType = z.enum([
  'manual',
  'barcode',
  'bluetooth',
]);

const WsStreamingStockContainer = z
  .object({
    id: z.string(),
    barcode: z.string(),
    gasType: z.string(),
    containerType: z.string(),
    fillPressure: z.number(),
    volume: z.number(),
    materialId: z.string(),
    materialDescription: z.string(),
    sapCustomerId: z.string().optional(),
  })
  .strict();

const WsStreamingContainerValvePairing = z
  .object({
    deviceIdentifier: z.string(),
    timestamp: z.number(),
    paringType: WsStreamingContainerValvePairingType,
    container: WsStreamingStockContainer,
  })
  .strict();

const WsStreamingValveUpdatePayload = z
  .object({
    deviceIdentifier: z.string(),
    serialNumber: z.string().optional(),
    customerId: z.string(),
    isTooCold: z.boolean().optional(),
    isTooHot: z.boolean().optional(),
    pressureBar: z.number().optional(),
    temperatureHousingCelsius: z.number().optional(),
    temperatureManometerCelsius: z.number().optional(),
    timestamp: z.number(),
    voltage: z.number().optional(),
    isCharging: z.boolean().optional(),
    isUsbConnected: z.boolean().optional(),
    counter: z.number().optional(),
    trackerId: z.string().optional(),
    containerPairing: WsStreamingContainerValvePairing.optional(),
    deviceName: z.string().nullable().optional(),
    group: z
      .object({
        id: z.string().uuid(),
        name: z.string().nullable(),
      })
      .strict()
      .optional(),
  })
  .strict();

export const WsStreamingValveUpdate = z
  .object({
    messageType: z.literal('valve-update'),
    messageId: z.string().uuid().optional(),
    updates: z.array(WsStreamingValveUpdatePayload),
  })
  .strict();
export type WsStreamingValveUpdateType = z.infer<typeof WsStreamingValveUpdate>;
export type WsStreamingValveUpdatePayloadType = z.infer<
  typeof WsStreamingValveUpdatePayload
>;

const WsInventoryThresholdSchema = z
  .object({
    id: z.string(),
    name: z.string(),
    color: z.string(),
    minFullBottles: z.number(),
  })
  .strict();

const WsPressureThresholdSchema = z.object({
  id: z.string(),
  name: z.string(),
  color: z.string(),
  pressure: z.number(),
  minPackets: z.number(),
});

const WsStreamingInventoryThresholdNotification = z
  .object({
    materialId: z.string(),
    customerId: z.string(),
    bottles: z.number(),
    id: z.string(),
    createdAt: z.string().datetime(),
    readAt: z.string().datetime().optional(),
    platformNotificationRecipientAssignmentId: z.string(),
    type: z.literal('inventoryThreshold'),
    threshold: WsInventoryThresholdSchema,
  })
  .strict();
export type WsStreamingInventoryThresholdNotificationType = z.infer<
  typeof WsStreamingInventoryThresholdNotification
>;

const WsStreamingSuspiciousValuesNotification = z.object({
  id: z.string(),
  createdAt: z.string().datetime(),
  readAt: z.string().datetime().optional(),
  platformNotificationRecipientAssignmentId: z.string(),
  pressure: z.number(),
  temperatureManometer: z.number(),
  deviceId: z.string(),
  serialNumber: z.string(),
  customerId: z.string(),
  type: z.literal('suspiciousValues'),
});
export type WsStreamingSuspiciousValuesNotificationType = z.infer<
  typeof WsStreamingSuspiciousValuesNotification
>;

const WsStreamingValveOfflineNotification = z.object({
  id: z.string(),
  createdAt: z.string().datetime(),
  readAt: z.string().datetime().optional(),
  platformNotificationRecipientAssignmentId: z.string(),
  deviceId: z.string(),
  serialNumber: z.string(),
  customerId: z.string(),
  type: z.literal('valveOffline'),
});
export type WsStreamingValveOfflineNotificationType = z.infer<
  typeof WsStreamingValveOfflineNotification
>;

const WsStreamingPressureThresholdNotification = z.object({
  id: z.string(),
  createdAt: z.string().datetime(),
  readAt: z.string().datetime().optional(),
  platformNotificationRecipientAssignmentId: z.string(),
  pressure: z.number(),
  deviceId: z.string(),
  serialNumber: z.string(),
  customerId: z.string(),
  type: z.literal('pressureThreshold'),
  threshold: WsPressureThresholdSchema,
});
export type WsStreamingPressureThresholdNotificationType = z.infer<
  typeof WsStreamingPressureThresholdNotification
>;

export const WsStreamingNotification = z.union([
  WsStreamingInventoryThresholdNotification,
  WsStreamingSuspiciousValuesNotification,
  WsStreamingValveOfflineNotification,
  WsStreamingPressureThresholdNotification,
]);

export const WsStreamingNotifications = z
  .object({
    messageType: z.literal('notifications'),
    messageId: z.string().uuid().optional(),
    notifications: z.array(WsStreamingNotification),
  })
  .strict();
export type WsStreamingNotificationsType = z.infer<
  typeof WsStreamingNotifications
>;

export const WsStreamingSchema = z.discriminatedUnion('messageType', [
  WsStatusSchema,
  WsStreamingValveUpdate,
  WsStreamingNotifications,
]);
export type WsStreamingSchemaType = z.infer<typeof WsStreamingSchema>;

export const WsSecProtocolName = 'gaas';
