import { createAction } from 'typesafe-actions';
import { makeAsyncAction } from '../../util/makeAsyncAction';

export const connectWebsocket = createAction('WEBSOCKET_CONNECT')<{
  token: string;
}>();

export const websocketConnected = createAction('WEBSOCKET_CONNECTED')();

export const setWebsocketCustomer = makeAsyncAction('WEBSOCKET_SET_CUSTOMER')<
  string,
  string,
  void
>();
